<template>
  <div class="container-fluid p-4">
    <h1>Devices</h1>
    <div class="d-flex flex-column pt-4">
      <h2>Devices List</h2>
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between pt-4 pb-2">
          <div class="d-flex justify-content-start">
            <b-button variant="success" size="sm" v-b-modal.form-add-device>
              <i class="fa fa-plus mr-1"></i>Add Device
            </b-button>
          </div>
          <div class="d-flex justify-content-end w-30 d-inline-block mr-2">
            <b-form-input
              id="search_bar"
              size="sm"
              type="text"
              placeholder="Search..."
              v-model="searchTerms"
            ></b-form-input>
          </div>
        </div>
        <div class="d-flex justify-content-end pt-4 pb-2">
          <download-excel
            class="ml-1 btn btn-secondary"
            :data="devices"
            :fields="jsonFields"
            worksheet="My Worksheet"
            type="csv"
          >
            <i class="fas fa-file-csv"></i> Export as CSV
          </download-excel>
        </div>
        <div class="d-flex justify-content-between">
          <!-- Main table element -->
          <b-table
            striped
            :busy="busy"
            :items="devices"
            :fields="deviceTableFields"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="searchTerms"
            small
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-white my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(name)="row">
              {{ row.item.data.details.name }}
            </template>

            <template #cell(type)="row">
              <div class="d-flex justify-content-start">
                <div
                  v-if="row.item.type == 'camera'"
                  class="device-icon device-icon-camera mr-2"
                >
                  <i class="fa fa-video"></i>
                </div>
                <div
                  v-else-if="row.item.type == 'controller'"
                  class="device-icon device-icon-controller mr-2"
                >
                  <i class="fa fa-copyright"></i>
                </div>
                {{ row.item.ip }}
              </div>
            </template>

            <template #cell(status)="row">
              <div class="d-flex justify-content-start">
                <DeviceStatus :status="row.item.status"></DeviceStatus>
                {{ statusNaming(row.item.status) }}
              </div>
            </template>
            <template #cell(actions)="row">
              <b-button
                variant="secondary"
                size="sm"
                class="mr-1"
                v-if="
                  row.item.monitor_type == 'NX' ||
                  row.item.monitor_type == 'DIGIFORT' ||
                  row.item.monitor_type == 'EP_P1'
                "
                disabled
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <b-button
                variant="secondary"
                size="sm"
                @click="showUpdateDeviceForm(row.item)"
                v-b-modal.form-update-device
                v-b-tooltip.hover
                title="Update Device"
                class="d-none d-md-inline-block mr-2"
                v-else-if="
                  row.item.monitor_type != 'NX' ||
                  row.item.monitor_type == 'DIGIFORT' ||
                  row.item.monitor_type != 'EP_P1'
                "
                ><i class="fa fa-edit"></i>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                v-if="
                  row.item.monitor_type == 'NX' ||
                  row.item.monitor_type == 'DIGIFORT' ||
                  row.item.monitor_type == 'EP_P1'
                "
                disabled
              >
                <i class="fa fa-trash-alt"></i>
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="confirmRemoveDevice(row.item, row.index)"
                v-b-tooltip.hover
                title="Remove Device"
                v-else-if="
                  row.item.monitor_type != 'NX' ||
                  row.item.monitor_type == 'DIGIFORT' ||
                  row.item.monitor_type != 'EP_P1'
                "
              >
                <i class="fa fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
        </div>
      </div>

      <b-modal
        id="form-add-device"
        title="Add Device (Camera/Controller)"
        ref="formAddDevice"
        centered
        hide-footer
      >
        <b-form @submit="onSubmitAddDevice">
          <div class="d-flex flex-column bd-highlight mb-3">
            <b-form-group
              id="input-group-url"
              label="Device URL:"
              label-for="input-url"
              class="mr-2 w-100"
            >
              <b-form-input
                id="input-url"
                v-model="formAdd.url"
                type="url"
                placeholder="Enter URL"
                :state="validation"
              ></b-form-input>

              <b-form-invalid-feedback :state="validation">
                {{ existDeviceText }}
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation">
                Looks Good.
              </b-form-valid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-name"
              label="Device Name:"
              label-for="input-name"
              class="mr-2 w-100"
            >
              <b-form-input
                id="input-name"
                v-model="formAdd.device_name"
                type="text"
                placeholder="Enter Device Name"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-model"
              label="Device Model:"
              label-for="input-model"
              class="mr-2 w-100"
            >
              <b-form-input
                id="input-model"
                v-model="formAdd.device_model"
                type="text"
                placeholder="Enter Device Model"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-type"
              label="Device Type:"
              label-for="input-type"
              class="mr-2 w-100"
            >
              <b-form-select
                id="input-type"
                v-model="formAdd.device_type"
                :options="deviceOption"
              ></b-form-select>
            </b-form-group>
          </div>
          <hr class="bg-light" />
          <div>
            <b-form-group
              class="m-1"
              label="Auth Setting"
              label-for="input-auth"
            ></b-form-group>
            <div class="mb-3">
              <div class="input-group is-invalid">
                <div class="input-group-prepend">
                  <label
                    class="input-group-text"
                    for="validatedInputGroupSelect"
                    >Type</label
                  >
                </div>
                <div class="input-group-prepend" id="validatedInputGroupSelect">
                  <b-button
                    class="btn btn-outline-light bg-dark text-white"
                    id="none"
                    >none</b-button
                  >
                  <b-button
                    class="btn btn-outline-light bg-dark text-white"
                    id="basic"
                    v-b-toggle.accordion-4
                  >
                    basic
                  </b-button>
                  <b-button
                    class="btn btn-outline-light bg-dark text-white"
                    id="bearer"
                    v-b-toggle.accordion-5
                  >
                    bearer
                  </b-button>
                  <b-button
                    class="btn btn-outline-light bg-dark text-white"
                    id="custom"
                    v-b-toggle.accordion-6
                  >
                    custom
                  </b-button>
                </div>
              </div>
            </div>
            <b-collapse invisible id="accordion-4" accordion="my-accordion">
              <div class="mb-3">
                <div class="input-group is-invalid">
                  <div class="input-group" id="basic">
                    <div class="row">
                      <div class="col">
                        <input
                          v-model="formAdd.username"
                          type="text"
                          class="form-control"
                          placeholder="Username"
                        />
                      </div>
                      <div class="col">
                        <input
                          v-model="formAdd.password"
                          type="text"
                          class="form-control"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
            <b-collapse invisible id="accordion-5" accordion="my-accordion">
              <div class="mb-3">
                <div class="input-group" id="bearer">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="selectBerearMethod"
                      >Auth Method</label
                    >
                  </div>
                  <b-form-select
                    id="selectBerearMethod"
                    v-model="formAdd.auth_method"
                    :options="authMethodOption"
                  ></b-form-select>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formAdd.auth_url"
                      placeholder="Auth URL"
                    />
                  </div>
                  <div>
                    <b-form-group
                      id="input-group-api-headers"
                      label="Auth API Header:"
                      label-for="input-api-headers"
                      class="w-100"
                    >
                      <b-form-input
                        id="input-api-headers"
                        v-model="formAdd.api_header"
                        type="text"
                        placeholder="Enter API Header"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="input-group-api-body"
                      label="Auth API Body:"
                      label-for="input-api-body"
                      class="w-100"
                    >
                      <b-form-input
                        id="input-api-body"
                        v-model="formAdd.api_body"
                        type="text"
                        placeholder="Enter API Body"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-collapse>
            <b-collapse invisible id="accordion-6" accordion="my-accordion">
              <div class="mb-3">
                <div class="input-group is-invalid">
                  <div class="input-group" id="custom">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="selectBerearMethod"
                        >Auth Method</label
                      >
                    </div>
                    <b-form-select
                      id="selectBerearMethod"
                      v-model="formAdd.auth_method"
                      :options="authMethodOption"
                    ></b-form-select>
                    <b-form-group
                      id="input-group-api-headers"
                      label="Auth API Header:"
                      label-for="input-api-headers"
                      class="w-100"
                    >
                      <b-form-input
                        id="input-api-headers"
                        v-model="formAdd.api_header"
                        type="text"
                        placeholder="Enter API Header"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <!-- <hr class="bg-light">
          <div>
            <b-form-group v-b-toggle.collapse-7 class="m-1" label="SSL Settings" label-for="input-auth" data-toggle="collapse" aria-expanded="true" ></b-form-group>
            <b-collapse invisible id="collapse-7">
              <div class="mb-3">
                <div class="input-group is-invalid">
                  <div class="custom-control custom-checkbox">
                    <input v-model="formAdd.ssl" type="checkbox" class="custom-control-input" id="useSSL">
                    <label class="custom-control-label" for="useSSL" v-b-toggle.collapse-8>Use SSL</label>
                  </div>
                </div>
              </div>
            </b-collapse>
            <b-collapse invisible id="collapse-8">
              <div class="mb-3">
                <div class="input-group is-invalid">
                  <div class="input-group" id="useSSL">
                       <b-form-group id="input-group-ca-file" label="CA File" label-for="input-ca-file" class=" w-100">
                          <b-form-input id="input-ca-file" v-model="formAdd.ca_file" type="text" placeholder="CA File"></b-form-input>
                        </b-form-group>
                       <b-form-group id="input-group-pub-cert" label="Public Certificate:" label-for="input-pub-cert" class=" w-100">
                          <b-form-input id="input-pub-cert" v-model="formAdd.pub_cert" type="text" placeholder="Enter Public Certificate"></b-form-input>
                        </b-form-group>
                       <b-form-group id="input-group-priv-cert" label="Private Certificate:" label-for="input-priv-cert" class=" w-100">
                          <b-form-input id="input-priv-cert" v-model="formAdd.priv_cert" type="text" placeholder="Enter Private Certificate"></b-form-input>
                        </b-form-group>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div> -->
          <div class="text-right">
            <b-button type="submit" variant="primary" v-if="validUrl == true"
              >Submit</b-button
            >
            <b-button
              type="submit"
              variant="primary"
              v-else-if="validUrl == false"
              disabled
              >Submit</b-button
            >
            <!-- <b-button type="submit" variant="primary">Submit</b-button> -->
          </div>
        </b-form>
      </b-modal>

      <b-modal
        id="form-update-device"
        title="Update Device (Camera/Controller)"
        ref="formUpdateDevice"
        centered
        hide-footer
      >
        <b-form @submit="onSubmitUpdateDevice">
          <div class="d-flex flex-column bd-highlight mb-3">
            <b-form-group
              id="input-group-url"
              label="Device URL:"
              label-for="input-url"
              class="mr-2 w-100"
            >
              <b-form-input
                id="input-url"
                v-model="formUpdate.url"
                type="url"
                placeholder="Enter URL"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-name"
              label="Device Name:"
              label-for="input-name"
              class="mr-2 w-100"
            >
              <b-form-input
                id="input-name"
                v-model="formUpdate.device_name"
                type="text"
                placeholder="Enter Device Name"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-model"
              label="Device Model:"
              label-for="input-model"
              class="mr-2 w-100"
            >
              <b-form-input
                id="input-model"
                v-model="formUpdate.device_model"
                type="text"
                placeholder="Enter Device Model"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-type"
              label="Device Type:"
              label-for="input-type"
              class="mr-2 w-100"
            >
              <b-form-select
                id="input-type"
                v-model="formUpdate.device_type"
                :options="deviceOption"
              ></b-form-select>
            </b-form-group>
          </div>
          <hr class="bg-light" />
          <div>
            <b-form-group
              class="m-1"
              label="Auth Setting"
              label-for="input-auth"
            ></b-form-group>
            <div class="mb-3">
              <div class="input-group is-invalid">
                <div class="input-group-prepend">
                  <label
                    class="input-group-text"
                    for="validatedInputGroupSelect"
                    >Type</label
                  >
                </div>
                <div class="input-group-prepend" id="validatedInputGroupSelect">
                  <b-button
                    class="btn btn-outline-light bg-dark text-white"
                    id="none"
                    >none</b-button
                  >
                  <b-button
                    class="btn btn-outline-light bg-dark text-white"
                    id="basic"
                    v-b-toggle.accordion-4
                  >
                    basic
                  </b-button>
                  <b-button
                    class="btn btn-outline-light bg-dark text-white"
                    id="bearer"
                    v-b-toggle.accordion-5
                  >
                    bearer
                  </b-button>
                  <b-button
                    class="btn btn-outline-light bg-dark text-white"
                    id="custom"
                    v-b-toggle.accordion-6
                  >
                    custom
                  </b-button>
                </div>
              </div>
            </div>
            <b-collapse invisible id="accordion-4" accordion="my-accordion">
              <div class="mb-3">
                <div class="input-group is-invalid">
                  <div class="input-group" id="basic">
                    <div class="row">
                      <div class="col">
                        <input
                          v-model="formUpdate.username"
                          type="text"
                          class="form-control"
                          placeholder="Username"
                        />
                      </div>
                      <div class="col">
                        <input
                          v-model="formUpdate.password"
                          type="text"
                          class="form-control"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
            <b-collapse invisible id="accordion-5" accordion="my-accordion">
              <div class="mb-3">
                <div class="input-group" id="bearer">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="selectBerearMethod"
                      >Auth Method</label
                    >
                  </div>
                  <b-form-select
                    id="selectBerearMethod"
                    v-model="formUpdate.auth_method"
                    :options="authMethodOption"
                  ></b-form-select>
                  <div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="formUpdate.auth_url"
                      placeholder="Auth URL"
                    />
                  </div>
                  <div>
                    <b-form-group
                      id="input-group-api-headers"
                      label="Auth API Header:"
                      label-for="input-api-headers"
                      class="w-100"
                    >
                      <b-form-input
                        id="input-api-headers"
                        v-model="formUpdate.api_header"
                        type="text"
                        placeholder="Enter API Header"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      id="input-group-api-body"
                      label="Auth API Body:"
                      label-for="input-api-body"
                      class="w-100"
                    >
                      <b-form-input
                        id="input-api-body"
                        v-model="formUpdate.api_body"
                        type="text"
                        placeholder="Enter API Body"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-collapse>
            <b-collapse invisible id="accordion-6" accordion="my-accordion">
              <div class="mb-3">
                <div class="input-group is-invalid">
                  <div class="input-group" id="custom">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="selectBerearMethod"
                        >Auth Method</label
                      >
                    </div>
                    <b-form-select
                      id="selectBerearMethod"
                      v-model="formUpdate.auth_method"
                      :options="authMethodOption"
                    ></b-form-select>
                    <b-form-group
                      id="input-group-api-headers"
                      label="Auth API Header:"
                      label-for="input-api-headers"
                      class="w-100"
                    >
                      <b-form-input
                        id="input-api-headers"
                        v-model="formUpdate.api_header"
                        type="text"
                        placeholder="Enter API Header"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <div class="text-right">
            <b-button type="submit" variant="primary">Update</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import DeviceStatus from "@/components/FloorplanDevices/DeviceStatus/DeviceManagementStatus";
// import JsonCSV from "vue-json-csv";
import JsonExcel from "vue-json-excel";

let utils = require("@/assets/global/js/utils.js");

const username_regex = helpers.regex(
  "username_regex",
  /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
);

export default {
  components: {
    DeviceStatus,
    downloadExcel: JsonExcel
  },
  data: function () {
    return {
      devices: [],
      device_list: [],
      deviceListOriginal: [],
      // cameraList: [],
      // controllerList: [],
      deviceTableFields: [
        {
          key: "name",
          label: "Device Name",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "type",
          label: "Device Details",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Actions" }
      ],

      // export to CSV
      jsonFields: {
        URL: {
          field: "ip"
        },
        "Device Details": {
          field: "type"
        },
        Status: {
          field: "status",
          callback: (value) => {
            try {
              if (value == 0) {
                return "Offline";
              } else if (value == 1) {
                return "Online";
              }
            } catch (e) {
              return "-";
            }
          }
        },
        "Device Name": {
          field: "data.details.name"
        },
        "Monitor Type": {
          field: "monitor_type"
        }
      },
      busy: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      // table search
      searchTerms: "",
      formAdd: {
        url: null,
        device_name: null,
        device_model: null,
        username: null,
        password: null,
        api_header: null,
        api_body: null,
        ssl: null,
        ca_file: null,
        pub_cert: null,
        priv_cert: null,
        auth_method: null,
        auth_url: null,
        device_type: null
      },
      formUpdate: {
        url: null,
        device_name: null,
        device_model: null,
        username: null,
        password: null,
        api_header: null,
        api_body: null,
        ssl: null,
        ca_file: null,
        pub_cert: null,
        priv_cert: null,
        auth_method: null,
        auth_url: null,
        device_type: null
      },
      deviceOption: [
        { value: null, text: "Please select an option" },
        { value: "DEV_CAM", text: "Camera" },
        { value: "DEV_CTRL", text: "Controller" }
      ],
      authMethodOption: [
        { value: null, text: "Please select an option" },
        { value: "get", text: "Get" },
        { value: "post", text: "Post" }
      ],
      allDeviceSeledted: false,
      devicesSelected: [],
      showMoreActions: false,
      selectedText: "",
      CSV: "Export CSV",
      json_data: [],
      formUpdateID: null,
      existDeviceText: "Url must contain http or https protocol.",
      validUrl: false
    };
  },
  computed: {
    ...mapGetters({
      // getCurrentUser: 'session/getCurrentUser',
      getCameras: "device/getCameras",
      getControllers: "device/getControllers",
      getAPIServerUrl: "session/getAPIServerURL"
    }),
    validation() {
      return this.isValidHttpUrl(this.formAdd.url);
    }
  },
  watch: {
    getCameras: {
      handler: function (n, o) {
        this.updateCameras(n);
      },
      deep: true
    },
    getControllers: {
      handler: function (n, o) {
        this.updateControllers(n);
      },
      deep: true
    }
  },
  mounted() {
    this.mergeDevices();
  },
  methods: {
    mergeDevices: function () {
      let devices = [];
      for (let i in this.getCameras) {
        devices.push(this.getCameras[i]);
      }
      for (let i in this.getControllers) {
        let obj = this.getControllers[i];
        let name = obj["data"]["details"]["name"];

        if (obj["data"]["details"]["controller_name"] != undefined) {
          obj["data"]["details"]["name"] =
            this.getControllers[i]["data"]["details"]["controller_name"];
        } else if (obj["data"]["details"]["controller_name"] == undefined) {
          obj["data"]["details"]["name"] = name;
        }
        devices.push(obj);
      }
      this.devices = devices;
    },
    updateCameras: function (cameras) {
      let devices = [];
      for (let i in cameras) {
        devices.push(cameras[i]);
        // console.log("camera", cameras[i])
      }

      for (let i in this.getControllers) {
        let obj = this.getControllers[i];
        let name = obj["data"]["details"]["name"];

        if (obj["data"]["details"]["controller_name"] != undefined) {
          obj["data"]["details"]["name"] =
            this.getControllers[i]["data"]["details"]["controller_name"];
        } else if (obj["data"]["details"]["controller_name"] == undefined) {
          obj["data"]["details"]["name"] = name;
        }
        devices.push(obj);
        // console.log("devices getControllers", this.getControllers)
      }
      this.devices = devices;

      this.exportCSV();
    },
    updateControllers: function (controllers) {
      let devices = [];
      for (let i in this.getCameras) {
        devices.push(this.getCameras[i]);
        // console.log("this.getCameras", this.getCameras[i])
      }

      for (let i in controllers) {
        let obj = controllers[i];
        let name = obj["data"]["details"]["name"];

        if (obj["data"]["details"]["controller_name"] != undefined) {
          obj["data"]["details"]["name"] =
            this.getControllers[i]["data"]["details"]["controller_name"];
        } else if (obj["data"]["details"]["controller_name"] == undefined) {
          obj["data"]["details"]["name"] = name;
        }
        // console.log("obj", obj)

        devices.push(obj);
        // console.log("controllers", controllers)
      }
      this.devices = devices;
      // console.log("devices controllers", this.devices)
    },
    statusNaming: function (status) {
      if (status == 0 || status == "0" || status == undefined) {
        return "Offline";
      } else if (status == 1 || status == "1") {
        return "Online";
      }
    },
    deviceTypeNaming: function (device_type) {
      if (device_type == "DEV_CAM") {
        return "camera";
      } else if (device_type == "DEV_CTRL") {
        return "controller";
      }
    },
    onSubmitAddDevice: async function (e) {
      e.preventDefault();
      var $this = this;
      var API_URL = $this.getAPIServerUrl + "/api/monitorservice/device/";
      var devices = this.devices;
      var dev_url = "";
      const client = $this.$root.getAjaxFetchClient();
      var type = this.deviceTypeNaming($this.formAdd.device_type);

      var device_data = {
        url: $this.formAdd.url,
        active: true,
        group: $this.formAdd.device_type,
        type: type,
        meta: {
          name: $this.formAdd.device_name,
          model: $this.formAdd.device_model,
          username: $this.formAdd.username,
          password: $this.formAdd.password,
          api_header: $this.formAdd.api_header,
          api_body: $this.formAdd.api_body,
          ssl: $this.formAdd.ssl,
          ca_file: $this.formAdd.ca_file,
          pub_cert: $this.formAdd.pub_cert,
          priv_cert: $this.formAdd.priv_cert,
          auth_method: $this.formAdd.auth_method,
          auth_url: $this.formAdd.auth_url
        }
      };
      let data = await client.postRequest(API_URL, device_data);

      if (data.result !== null) {
        var url = $this.formAdd.url;
        var ip = new URL(url).host;
        var check = this.isValidHttpUrl(url);

        var result = data.result;
        for (var i in result) {
          var id = result[i];
          let obj = {
            id: $this.formAdd.url,
            ip: ip,
            name: device_data.meta.name,
            device_group: device_data.group,
            type: type,
            data: {
              details: {
                ...device_data.meta,
                id: id
              },
              meta: device_data.meta,
              server_url: ip
            },
            status: 0
          };
          $this.$store.dispatch("device/addDevice", obj);
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text:
              "Device " + device_data.meta.name + " created successfully.",
            message_type: "success"
          });
        }
        $this.devices.push(device_data);
        $this.$refs.formAddDevice.hide();
        $this.updateCameras();
        $this.updateControllers();
        $this.formAdd = {
          url: null,
          device_name: null,
          device_model: null,
          username: null,
          password: null,
          api_header: null,
          api_body: null,
          ssl: null,
          ca_file: null,
          pub_cert: null,
          priv_cert: null,
          auth_method: null,
          device_type: null,
          status: null
        };
      } else {
        await $this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: "data is null",
          message_type: "danger"
        });
      }
    },
    isValidHttpUrl: function (string) {
      let url;

      try {
        url = new URL(string);
        var url_href = url.origin;
        var exist = this.isURLExist(url_href);

        if (exist == true) {
          this.validUrl = true;
          return true;
        } else {
          this.existDeviceText = " Url already exist. Please enter another URL";
          this.validUrl = false;
          return false;
        }
      } catch (_) {
        return false;
      }
    },
    isURLExist: function (url) {
      var $this = this;
      var device = $this.devices;
      var device_url = "";

      var check = $this.devices.filter(function (item) {
        if (url == item.id) {
          device_url = "false";
        }
      });

      if (device_url == "false") {
        return false;
      } else {
        return true;
      }
    },
    showUpdateDeviceForm: function (item) {
      // console.log("show update form");
      // console.log(item);

      let type = "";

      if (item.type == "camera") {
        type = "DEV_CAM";
      } else if (item.type == "controller") {
        type = "DEV_CTRL";
      }

      this.formUpdate.url = item.id;
      this.formUpdate.device_name = item.data.details.name;
      this.formUpdate.device_model = item.data.details.model;
      this.formUpdate.device_type = type;
      this.formUpdate.username = item.data.details.username;
      this.formUpdate.password = item.data.details.password;
      this.formUpdateID = item.id;
    },
    onSubmitUpdateDevice: function (e) {
      e.preventDefault();
      var $this = this;
      var devices = $this.devices;
      var type = this.deviceTypeNaming($this.formUpdate.device_type);
      var url = this.formUpdateID;
      var ip = new URL(url).host;

      for (var i in devices) {
        var device_data = devices[i];
        var device_url = device_data["id"];

        if (device_url == url) {
          var device_id = device_data.data.details.id;
          var API_URL =
            $this.getAPIServerUrl +
            "/api/monitorservice/device/" +
            device_id +
            "/";

          var device_update = {
            url: $this.formUpdate.url,
            active: true,
            group: $this.formUpdate.device_type,
            type: type,
            meta: {
              name: $this.formUpdate.device_name,
              model: $this.formUpdate.device_model,
              username: $this.formUpdate.username,
              password: $this.formUpdate.password,
              api_header: $this.formUpdate.api_header,
              api_body: $this.formUpdate.api_body,
              ssl: $this.formUpdate.ssl,
              ca_file: $this.formUpdate.ca_file,
              pub_cert: $this.formUpdate.pub_cert,
              priv_cert: $this.formUpdate.priv_cert,
              auth_method: $this.formUpdate.auth_method,
              auth_url: $this.formUpdate.auth_url
            }
          };
          const client = $this.$root.getAjaxFetchClient();

          client
            .putRequest(API_URL, device_update)
            .then((data) => {
              if (data != null) {
                let obj = {
                  id: $this.formUpdate.url,
                  ip: ip,
                  name: device_update.meta.name,
                  device_group: device_update.group,
                  type: type,
                  data: {
                    details: {
                      ...device_update.meta,
                      id: device_id
                    },
                    meta: device_update.meta,
                    server_url: ip
                  },
                  status: 0
                };
                if (device_update.type == "camera") {
                  $this.$store.dispatch("device/updateCamera", obj);
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text:
                      "Camera " + device_update.url + " updated successfully.",
                    message_type: "success"
                  });
                } else if (device_update.type == "controller") {
                  $this.$store.dispatch("device/updateController", obj);
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text:
                      "Controller " +
                      device_update.url +
                      " updated successfully.",
                    message_type: "success"
                  });
                }
                $this.devices.push(device_update);
                $this.$refs.formUpdateDevice.hide();
                $this.updateCameras();
                $this.updateControllers();
                $this.formUpdate = {
                  url: null,
                  device_name: null,
                  device_model: null,
                  username: null,
                  password: null,
                  api_header: null,
                  api_body: null,
                  ssl: null,
                  ca_file: null,
                  pub_cert: null,
                  priv_cert: null,
                  auth_method: null,
                  device_type: null,
                  status: null
                };
              } else {
                //data null
              }
            })
            .catch((err) => {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
            });
        }
      }
    },
    exportCSV: function () {
      var $this = this;

      var dev_list = $this.devices;
      for (var i in dev_list) {
        var dev_data = dev_list[i];
        var dev_url = dev_data["ip"];
        var dev_status = dev_data["status"];
        var status = "";
        var monitor_type = dev_data["monitor_type"];
        var dev_details = dev_data["data"]["details"];
        var dev_name = dev_details["name"];

        status = $this.statusNaming(dev_status);

        var dev_result = {
          URL: dev_url,
          Type: dev_data["type"],
          Status: status,
          Name: dev_name,
          "Monitor Type": monitor_type
        };
        $this.json_data.push(dev_result);
      }
    },
    searchDeviceList: function () {
      var $this = this;

      if ($this.searchTerms != null || $this.searchTerms != "") {
        $this.devices = $this.devices.filter(function (x) {
          var type_includes = x.type
            .toUpperCase()
            .includes($this.searchTerms.toUpperCase());
          var name_includes = x.data.details.name
            .toUpperCase()
            .includes($this.searchTerms.toUpperCase());
          var ip_includes = x.ip.includes($this.searchTerms);

          return ip_includes || type_includes || name_includes;
        });
      } else {
        $this.mergeDevices();
      }
    },
    // --- Table row selected ---
    selectAllDevices: function (e) {
      var $this = this;
      var device_list_id = [];
      if (e == true) {
        for (var i in $this.device_list) {
          var device = $this.device_list[i];
          var device_value = device.type + "_" + device.ip;
          device_list_id.push(device_value);
        }
      }
      $this.devicesSelected = device_list_id;
      if ($this.devicesSelected.length > 0) {
        $this.showMoreActions = true;
        $this.selectedText = $this.devicesSelected.length + " selected";
      } else {
        $this.showMoreActions = false;
        $this.selectedText = "";
      }
    },
    selectSingleDevice: function (e) {
      var $this = this;
      if ($this.devicesSelected.length > 0) {
        $this.showMoreActions = true;
        $this.selectedText = $this.devicesSelected.length + " selected";
      } else {
        $this.showMoreActions = false;
        $this.selectedText = "";
      }
    },
    confirmRemoveDevice: function (device_data) {
      // e.preventDefault();
      var $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm removing " + device_data.ip + " ?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            var params = { cameras: [], controllers: [] };
            var id = "";
            if (device_data.type == "camera") {
              id = device_data.id;
              params["cameras"].push(device_data.id);
            } else if (device_data.type == "controller") {
              id = device_data.id;
              params["controllers"].push(device_data.id);
            }
            // console.log("params", params)
            $this.deleteDevice(params, id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteDevice: function (device_params, device_id) {
      var $this = this;
      var id_list = [];
      id_list = device_params["cameras"].concat(device_params["controllers"]);

      var APIURL = $this.getAPIServerUrl + "/api/monitorservice/device/";
      const clients = $this.$root.getAjaxFetchClient();

      clients
        .getRequest(APIURL)
        .then((data) => {
          let device_result = null;

          for (var deviceID in data["result"]) {
            device_result = data["result"];
            for (var d in device_result) {
              var device_data = device_result[d];

              for (var s in device_data) {
                var devices_data = device_data[s];
                var devices_url = devices_data["url"];
                var devices_id = devices_data["id"];

                if (devices_url == device_id) {
                  var id = devices_id;
                  //start delete
                  var API_URL =
                    $this.getAPIServerUrl +
                    "/api/monitorservice/device/" +
                    id +
                    "/";
                  const client = $this.$root.getAjaxFetchClient();
                  client
                    .deleteRequest(API_URL, { id_list: id_list })
                    .then((data) => {
                      if (data != null) {
                        this.$store.dispatch("session/addGlobalAlertMessage", {
                          message_text: "Removed devices",
                          message_type: "success"
                        });
                        for (var i in device_params.cameras) {
                          const camera_obj = $this.$store.getters[
                            "device/filterCameras"
                          ]({ id: device_params.cameras[i] });
                          if (camera_obj.length > 0) {
                            const device_id = camera_obj[0].id;
                            $this.$store.dispatch("device/deleteDevice", {
                              device_type: "camera",
                              id: device_id
                            });
                          }
                        }
                        for (var j in device_params.controllers) {
                          const controller_obj = $this.$store.getters[
                            "device/filterControllers"
                          ]({ id: device_params.controllers[j] });
                          // console.log("controller_obj", controller_obj);
                          if (controller_obj.length > 0) {
                            const device_id = controller_obj[0].id;
                            $this.$store.dispatch("device/deleteDevice", {
                              device_type: "controller",
                              id: device_id
                            });
                          }
                        }
                      }
                      $this.showMoreActions = false;
                      $this.selectedText = "";
                      $this.devicesSelected = [];
                      $this.mergeDevices();
                    })
                    .catch((err) => {
                      $this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text: err.detail,
                        message_type: "danger"
                      });
                      if (err.status == 401) {
                        $this.$store.dispatch("session/logoutSession");
                      }
                    });
                  //end delete
                }
              }
            }
          }
        })
        .catch((err) => {
          //err
        });
    }
  }
};
</script>

<style lang="scss">
@import "./App.scss";
</style>

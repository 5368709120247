<template>
  <div class="d-flex align-items-center">
    <div
      class="status-indicator mr-1"
      :class="[status == 1 ? onlineClass : offlineClass]"
    ></div>
  </div>
</template>

<script>
export default {
  name: "DeviceStatus",
  props: {
    status: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      onlineClass: "status-online",
      offlineClass: "status-offline",
      statusDisplay: "Online"
    };
  },
  watch: {
    status: {
      handler: function (n, o) {
        this.updateStatus();
      },
      deep: true
    }
  },
  mounted: function () {
    this.updateStatus();
  },
  methods: {
    updateStatus: function () {
      if (this.status == 1) {
        this.statusDisplay = "Online";
      } else {
        this.statusDisplay = "Offline";
      }
    }
  }
};
</script>

<style lang="scss">
@import "./DeviceStatus.scss";
</style>
